<template>
  <div class="register-step2">
    <top-bar back backroute="register" is-auth :img="logo" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="img">
        <img :src="img" alt="">
      </div>
      <div class="title"><t name="3.8" /></div>
      <n-input type="phone" :danger="$var('message')" v-bind="$form.input('phone')"></n-input>
      <n-button wide color="yellow" @click="getCode"><n-link /><t name="3.9" /></n-button>
    </div>
  </div>
</template>

<script>
import logo from 'assets/little-logo.png'
import img from 'assets/phone.png'

export default {
  name: 'RegisterStep2',
  data() {
    return {
      logo, img,
    }
  },
  computed: {
    role() {
      return this.$route.query.role
    },
    phone() {
      return this.$form.get('phone')
    },
  },
  watch: {
    phone() {
      this.useMask('phone', this.$form.get('phone'))
    },
  },
  created() {
    this.$form.init({
      phone: '',
    })
    this.$form.rules({
      phone: [ 'required', ],
    })
  },
  methods: {

    useMask(mask, value) {
      if (!/[A-Za-zА-Яа-яЁё]/.test(value) && value.length > 4) {
        this.$form.set('phone', this.applyMask(mask, value))
        this.maxlength = 18
      } else {
        this.$form.set('phone', value)
        this.maxlength = 32
      }
    },
    applyMask(mask, value) {
      if (mask === 'phone') {
        const digits = value.replace(/[^0-9]/g, '')

        let formattedNumber = '+7'

        if (digits.length > 1) {
          formattedNumber += ' (' + digits.slice(1, 4)
        }

        if (digits.length >= 5) {
          formattedNumber += ') ' + digits.slice(4, 7)
        }

        if (digits.length >= 8) {
          formattedNumber += ' ' + digits.slice(7, 9)
        }

        if (digits.length >= 10) {
          formattedNumber += '-' + digits.slice(9, 11)
        }

        return formattedNumber
      }

      return value
    },
    getCode() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.auth.checkPhone(this.$form.get('phone')).then(() => {
          $api.auth.sendSms(this.$form.get('phone')).then(() => {
            const role = this.role || ''
            this.$router.push({ name: 'confirm.code', query: { role: role, phone: this.$form.get('phone'), }, })
          }).finally(() => {
            this.$var('load', false)
          })
        }).catch((err) => {
          this.$var('load', false)
          this.$var('message', err.response.data.message)
        })
      } else {
        this.$var('message', 'Данные введены неверно')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.register-step2{
  padding-top: 100px;
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    margin: 0 auto 8px;
  }

  .title {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .n-input{
    margin-bottom: 16px;
  }

  .n-button{
    position: relative;

    a{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
